import moment from 'moment';
import { AppConfig } from '@app/app.config';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LocalStorageRefService } from '@app/shared/services/browser/local-storage-ref.service';
import { StringResult } from '@app/shared/models/string-result.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

const lgSfx = '\t(seat-tracker.service)';

// SEAT_TOKEN_PING_INTERVAL is expected in Seconds
const SEAT_TOKEN_PING_INTERVAL = 60;

// TODO look into web locks api https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API
@Injectable({ providedIn: 'root' })
export class SeatTrackerService {
  private _seatTokenRetrieved = new Subject<void>();

  public SeatTokenRetrievedObs = this._seatTokenRetrieved.asObservable();

  public get seatToken(): string {
    return this.localStorageService.seatToken;
  }

  public get seatTokenTimeStamp(): string {
    return this.localStorageService.seatTokenTimeStamp;
  }

  constructor(
    private authService: AuthenticationService,
    private config: AppConfig,
    private http: HttpService,
    private localStorageService: LocalStorageRefService,
    private logger: Logger
  ) {
    this.authService.retryAuth.subscribe(() => {
      this.localStorageService.seatTokenTimeStamp = moment().format();
      this.runKeepAlive(true);
    });
  }

  public checkSeatToken(): void {
    try {
      const currentTimeStamp = this.seatTokenTimeStamp;
      if (!currentTimeStamp || !this.seatToken) {
        this.localStorageService.seatTokenTimeStamp = moment().format();
        this.runKeepAlive();
      } else {
        if (
          currentTimeStamp &&
          moment.duration(moment().diff(moment(currentTimeStamp))).asSeconds() >
            SEAT_TOKEN_PING_INTERVAL
        ) {
          this.localStorageService.seatTokenTimeStamp = moment().format();
          this.runKeepAlive();
        }
      }
    } finally {
      setTimeout(() => {
        this.checkSeatToken();
      }, SEAT_TOKEN_PING_INTERVAL * 1000);
    }
  }

  public runKeepAlive(sendRetryLoginMsg?: boolean): void {
    this.keepAlive().subscribe(
      (result: StringResult) => {
        this.logger.log(
          'Keep alive request returned with token',
          result,
          moment().format('LTS'),
          lgSfx
        );
        if (result.result) {
          this.localStorageService.seatToken = result.result;
          if (sendRetryLoginMsg) {
            this._seatTokenRetrieved.next();
          }
        } else {
          this.logger.error(`Keep alive returned invalid token:`, result);
        }
      },
      (error) => {
        this.logger.error(`Keep alive failed:`, error.message, error.description);
      }
    );
  }

  public KeepDbSessionAlive(): void {
    // Note: this interval timing may need to be adjusted
    setInterval(() => this.keepAliveDb(), 45000);
  }

  private keepAliveDb(): void {
    this.logger.log('Keep alive db interval');
    if (this.authService.token) {
      this.logger.log('Calling keep db session alive', moment().format('LTS'));

      const API_ENDPOINT = `/api/v1/auth/keepalive-db/`;
      const endpoint = this.config.getServerUri() + API_ENDPOINT;

      this.http.postAny(endpoint, undefined, undefined, true).subscribe((resp) => {
        this.logger.log('Keep alive db response', resp);
      });
    }
  }

  private keepAlive(): Observable<StringResult> {
    const seatToken = this.localStorageService.seatToken;
    this.logger.log('Sending keep alive request with token', seatToken, lgSfx);

    const API_ENDPOINT = `/api/v1/auth/keepalive/`;
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http
      .post<StringResult>(endpoint, undefined, StringResult, undefined, true)
      .pipe(map((resp) => resp));
  }
}
